import React from 'react';
import { Sidenav, Nav } from 'rsuite';
import { NavLink } from 'react-router-dom'; // Import NavLink
import './SideNav.css';

function SideNav() {
  return (
    <div className="sidenav" style={{ width: 240 }}>
      <Sidenav defaultOpenKeys={['3', '4']}>
        <Sidenav.Body>
          <Nav activeKey="1">
            <Nav.Menu eventKey="1" title="JavaScript">
              <Nav.Item eventKey="1-2">
                <NavLink to="/javascript">JavaScript History and Introduction</NavLink>
              </Nav.Item>
              <Nav.Item eventKey="1-3">
                <NavLink to="/es6">ES6</NavLink>
              </Nav.Item>
              <Nav.Item eventKey="1-4">
                <NavLink to="/map">Map</NavLink>
              </Nav.Item>
              <Nav.Item eventKey="1-5">
                <NavLink to="/filter">Filter</NavLink>
              </Nav.Item>
            </Nav.Menu>
            <Nav.Menu eventKey="2" title="ReactJs">
              <Nav.Item eventKey="2-1">
                <NavLink to="/reactjs">Props in ReactJs</NavLink>
              </Nav.Item>
              <Nav.Item eventKey="2-2">
                <NavLink to="/es6">ES6</NavLink>
              </Nav.Item>
              <Nav.Item eventKey="2-3">
                <NavLink to="/map">Map</NavLink>
              </Nav.Item>
              <Nav.Item eventKey="2-4">
                <NavLink to="/filter">Filter</NavLink>
              </Nav.Item>
            </Nav.Menu>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
}

export default SideNav;
