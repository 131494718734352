import React from 'react';
import { NavLink } from "react-router-dom";  // Use NavLink here
import './Nav.css';
import { environment } from './../environments/environments.prod';

function Nav() {
  const apiUrl = environment.apiUrl;

  return (
    <>
      <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-dense css-i1agjc">
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/generateqr"
        >
          Generate QR
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/javascript"
        >
          JavaScript
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/reactjs"
        >
          Reactjs
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          Html
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          CSS
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          Java
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to=""
        >
          Spring Framework
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          Spring Boot
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          Hibernate
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          Spring ORM
        </NavLink>
        <NavLink
          className="MuiTypography-root MuiTypography-body2 MuiTypography-noWrap MuiLink-root MuiLink-underlineAlways css-tiuoi3"
          to="/"
        >
          Bootstrap
        </NavLink>
      </div>
    </>
  );
}

export default Nav;
