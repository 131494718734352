import React,{ useState} from "react";
import Header from "./Header";
import Nav from "./Nav";
import Footer from "./Footer";
import SideNav from "./SideNav";
import { useNavigate } from "react-router-dom";

function Blog2() {
  const [toggleMenu,setToggleMenu]=useState(false)
  const [headerResp, setHeaderResp] = useState(false);
  const navigate = useNavigate();

  function handleSideNav(resp) {
    if (resp) {
      setHeaderResp(resp);
    } else {
      setHeaderResp(resp);
    }
    
  }

  function navigateToPreviousPage(){
    navigate("/")
  }

  function navigateToNextPage(){
    navigate("/reactjs_useState")
  }
  return (
    <>
      <Header data={handleSideNav} />
        {headerResp? <SideNav/> : null}
      <Nav />
      <div className="container" style={{ fontFamily: "Calibri" }}>
        <div id="props_in_reactjs">
          <h4 style={{ fontSize: "2.5em" }}>
            <b>ReactJs</b>
          </h4>
          <br />
          <br />
          <p style={{ fontSize: "1.7em", fontWeight: "bold" }}>
            <u>About Props in ReactJs</u>
          </p>
          <p style={{ fontSize: "1.2em", textAlign: "justify" }}>
            Reactjs has a feature called props. In this article we will read
            about Props in reactjs. So, Let's start.
            <h5>What is Props ?</h5>
            Props is used to pass data from child component to parent component
            and vice versa.
            <br />
            <br />
            <h5>Why to use props ?</h5>
            We use it to pass data from child component to parent component and
            vice versa. Due to this we can get the data which is part of that
            component which is somehow in relation to that component.
          </p>
        </div>
      </div>
      {/* <div className="video">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/PrdsQVlTF2I?si=dGIQW7s33niwKdJN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
       </div> */}
       <br/><br/>
       <div className="btns" style={{display:'flex',justifyContent:'space-around'}}>
       <button type="submit" onClick={navigateToPreviousPage} className="btn btn-primary">Previous</button>
        <button type="submit" onClick={navigateToNextPage} className="btn btn-success">Next</button>
       </div>
      
      <Footer />
    </>
  );
}

export default Blog2;
